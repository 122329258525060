@import '~@mc/design-system/src/styles/base/base';

.navbar {
  position: sticky;
  top: 0;
  z-index: 100;
  transition:
    background-color 0.6s ease-in-out,
    transform 0.4s ease-in-out;

  &.scrolled {
    background: rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(12px);
  }

  &.show {
    transform: translateY(0);
  }

  &.hide {
    transform: translateY(-100%);
  }

  &.bottomBorder {
    border-bottom: 1px solid rgba(255, 255, 255, 0.20);
  }
}

.background {
  @media (max-width: $mc-bp-sm) {
    background: rgba(black, 0.5);
  }
}

.transparentBg {
  @media (max-width: $mc-bp-sm) {
    background: transparent;
    left: 0;
    position: absolute;
    top: 0;
    z-index: 1;
  }
}
