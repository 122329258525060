@import '~@mc/design-system/src/styles/base/base';

.container {
  width: 100%;

  @media (min-width: $mc-bp-sm) {
    max-width: 570px;
    border-radius: var(--mc-scale-6);
    height: 100%;
  }
}
